<template>
  <g id="coal">
    <polygon :points="left_part_points" fill="#999" stroke="#333" id="left_coal" />
    <polygon :points="right_part_points" fill="#999" stroke="#333" id="right_coal" />
  </g>
</template>

<script>

export default {
  props: {
    input_data: Object,
    results: Object,
    pixcel: Number,
    border_thickness: Number,
    start_x: Number,
  },
  data: function () {
    return {
      left_part_points: "",
      right_part_points: "",
    }
  },
  watch: { 
    input_data: function(){
      this.draw_svg()
    },
    start_x: function(){
      this.draw_svg()
    },
  },
  mounted(){
    this.draw_svg()
  },
  methods: {
    draw_svg(){
      let
        // длина перехода
        Ltotal = (this.results["Lpr1"] + this.results["Lpr2"] + this.results["Lpr3"]) * this.pixcel,
        // мощность пласта
        M = this.input_data["M"] * this.pixcel,

        // амплитуда нарушения
        H = this.input_data["H"] * this.pixcel, 

        // спуск или подъём
        violation_type = this.input_data["violation_type"],

        delta_lava = this.results["delta_lava"],

        // сдвиг угля по горизонтали (по углу сместителя)
        triange_offset_M = M / Math.tan(delta_lava * Math.PI/180),

        // сдвиг угля по горизонтали (по амплитуде)
        triange_offset_H = H / Math.tan(delta_lava * Math.PI/180),

        // сдвиг по горизонтали до начала нарушения
        start_x = this.start_x,

        // количество секций
        N = Math.ceil(this.input_data["Llava"] / this.input_data["l"]),

        width = N * this.input_data["l"] * this.pixcel

      // сдвигаем нарушение, чтобы оно было в центре
      if(delta_lava>0 && start_x) start_x += Ltotal / 3

      if(violation_type == "descent"){
        start_x -= triange_offset_M
        triange_offset_M = -triange_offset_M
        triange_offset_H = -triange_offset_H
      }

      let
        // точки левой части (спуск)
        left_part = {
          top_left: {x: 0, y: this.border_thickness},
          top_right: {x: start_x, y: this.border_thickness},
          bottom_right: {x: start_x-triange_offset_M, y: this.border_thickness+M},
          bottom_left: {x: 0, y: this.border_thickness+M},
        },

        // точки правой части (спуск)
        right_part = {
          top_left: {x: start_x - triange_offset_H, y: this.border_thickness + H},
          top_right: {x: width, y: this.border_thickness + H},
          bottom_right: {x: width, y: this.border_thickness + H + M},
          bottom_left: {x: start_x - triange_offset_H - triange_offset_M, y: this.border_thickness + H + M},
        }

      if(violation_type == "descent"){
        // при спуске опускаем левую часть на амплитуду нарушения H
        left_part.top_left.y += H
        left_part.top_right.y += H
        left_part.bottom_right.y += H
        left_part.bottom_left.y += H

        right_part = {
          top_left: {x: start_x + triange_offset_H, y: this.border_thickness},
          top_right: {x: width, y: this.border_thickness},
          bottom_right: {x: width, y: this.border_thickness + M},
          bottom_left: {x: start_x + triange_offset_H - triange_offset_M, y: this.border_thickness + M},
        }
      }

      this.left_part_points = `
          ${left_part.top_left.x}, ${left_part.top_left.y}
          ${left_part.top_right.x}, ${left_part.top_right.y}
          ${left_part.bottom_right.x}, ${left_part.bottom_right.y}
          ${left_part.bottom_left.x}, ${left_part.bottom_left.y}
      `

      this.right_part_points = `
          ${right_part.top_left.x}, ${right_part.top_left.y}
          ${right_part.top_right.x}, ${right_part.top_right.y}
          ${right_part.bottom_right.x}, ${right_part.bottom_right.y}
          ${right_part.bottom_left.x}, ${right_part.bottom_left.y}
      `
    }
  }
};
</script>
