<template>

  <div class="card mb-2">
    <div class="card-header">
      <h4 class="card-title">{{ name }}</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-3" style="padding:0 30px;">
          <div class="form-group mb-3 row">
            <label class="form-label col-8 col-form-label">Количество циклов, K</label>
            <div class="col">
              <input type="text" class="form-control" disabled v-model="result.K">
            </div>
          </div>

          <div class="form-group mb-3 row">
            <label class="form-label col-8 col-form-label">Количество секции, N</label>
            <div class="col">
              <input type="text" class="form-control" disabled v-model="result.N">
            </div>
          </div>

          <div class="form-group mb-3 row">
            <label class="form-label col-8 col-form-label">Поперечный угол, α'</label>
            <div class="col">
              <input type="text" class="form-control" disabled v-model="result.alpha_">
            </div>
          </div>

          <div class="form-group mb-3 row">
            <label class="form-label col-8 col-form-label">Продольный угол, β'</label>
            <div class="col">
              <input type="text" class="form-control" disabled v-model="result.beta_">
            </div>
          </div>


        </div>

        <div class="col-3" style="padding:0 30px;">

          <div class="form-group mb-3 row">
            <label class="form-label col-8 col-form-label">Длина участка (пад), L₁</label>
            <div class="col">
              <input type="text" class="form-control" disabled v-model="result.Lpad1">
            </div>
          </div>


          <div class="form-group mb-3 row">
            <label class="form-label col-8 col-form-label">Длина участка (пад), L₂</label>
            <div class="col">
              <input type="text" class="form-control" disabled v-model="result.Lpad2">
            </div>
          </div>

          <div class="form-group mb-3 row">
            <label class="form-label col-8 col-form-label">Длина участка (пад), L₃</label>
            <div class="col">
              <input type="text" class="form-control" disabled v-model="result.Lpad3">
            </div>
          </div>

          <div class="form-group mb-3 row">
            <label class="form-label col-8 col-form-label">Угол падения сместителя</label>
            <div class="col">
              <input type="text" class="form-control" disabled v-model="result.real_delta">
            </div>
          </div>

      </div>


      <div class="col-3" style="padding:0 30px;">
          <div class="form-group mb-3 row">
            <label class="form-label col-8 col-form-label">Длина участка (пр), L₁</label>
            <div class="col">
              <input type="text" class="form-control" disabled v-model="result.Lpr1">
            </div>
          </div>


          <div class="form-group mb-3 row">
            <label class="form-label col-8 col-form-label">Длина участка (пр), L₂</label>
            <div class="col">
              <input type="text" class="form-control" disabled v-model="result.Lpr2">
            </div>
          </div>

          <div class="form-group mb-3 row">
            <label class="form-label col-8 col-form-label">Длина участка (пр), L₃</label>
            <div class="col">
              <input type="text" class="form-control" disabled v-model="result.Lpr3">
            </div>
          </div>

        </div>

      <div class="col-3" style="padding:0 30px;">
        <pre style="max-height: 220px;">{{ logs }}</pre>
      </div>



  </div>
</div>

</div>


</template>

<script>
export default {
  props: {
    result: Object,
    logs: String,
    name: String,
  },
};
</script>

