<template>
  <g :transform="this.transform">
    <rect 
      :x="this.point.x"
      :y="this.point.y"
      :width="this.width"
      :height="10"
      fill="#fff"
      stroke="#333"
     />
    <rect 
      :x="this.point.x"
      :y="this.point.y+this.height-10"
      :width="this.width"
      :height="10"
      fill="#fff"
      stroke="#333"
     />
    <rect 
      :x="this.point.x+this.width/2-5"
      :y="this.point.y+10"
      :width="10"
      :height="this.height-20"
      fill="#fff"
      stroke="#333"
     />
    <rect 
      :x="this.point.x+this.width/2-7"
      :y="this.point.y+this.height-34"
      :width="14"
      :height="24"
      fill="#fff"
      stroke="#333"
     />
    <rect 
      :x="this.point.x"
      :y="this.point.y+this.height"
      :width="this.width"
      :height="this.bottom_stroke_width"
      fill="#f00"
      v-if="this.part=='L1'"
     />
    <rect 
      :x="this.point.x"
      :y="this.point.y+this.height+this.bottom_stroke_width"
      :width="this.width"
      :height="this.bottom_stroke_width"
      fill="#00f"
      v-if="this.part=='L1'"
     />
    <rect 
      :x="this.point.x"
      :y="this.point.y+this.height"
      :width="this.width"
      :height="this.bottom_stroke_width*2"
      fill="#f00"
      v-if="this.part=='L3'"
     />
    <rect 
      :x="this.point.x"
      :y="this.point.y+this.height"
      :width="this.width"
      :height="this.bottom_stroke_width"
      fill="#00f"
      v-if="this.part=='L2'"
     />
    <rect 
      :x="this.point.x"
      :y="this.point.y+this.height+this.bottom_stroke_width"
      :width="this.width"
      :height="this.bottom_stroke_width"
      fill="#f00"
      v-if="this.part=='L2'"
     />
    <text
      :x="this.point.x+2"
      :y="this.point.y+7"
      style="font-size:8px;"
    >
      {{ num }}
    </text>
  </g>
</template>

<script>

export default {
  props: {
    point: Object,
    alpha: Number,
    height: Number,
    width: Number,
    num: Number,
    part: String,
  },
  watch: { 
    point: function(){
      this.recalculate()
    },
    alpha: function(){
      this.recalculate()
    }
  },
  data: function () {
    return {
      transform: "",
      bottom_stroke_width: 3,
    }
  },
  methods: {
    recalculate(){
      let rotate_y = this.point.y + this.height
      this.transform = `rotate(${this.alpha} ${this.point.x} ${rotate_y})`
    }
  },
  mounted(){
    this.recalculate()
  },
};
</script>
