<template>
  <div id="svg_cont">
    <svg id="svg-top-view" class="svg-block" :style="{height:svg_block_height+'px', width:svg_block_width+'px'}"></svg>
    <div class="row" style="margin: 1em 0.5em;">
      <div class="col-2">
        <button type="button" @click="start_process()" class="btn btn-primary" style="margin-left: 1em;">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 4v16l13 -8z" /></svg>
          Запустить
        </button>
      </div>
      <div class="col-2">
        <button type="button" @click="stop_process()" class="btn btn-info" style="margin-left: 1em;">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="6" y="5" width="4" height="14" rx="1" /><rect x="14" y="5" width="4" height="14" rx="1" /></svg> Остановить
        </button>
      </div>
      <div class="col-2">
        <button type="button" @click="showCorrectionForm()" class="btn btn-warning" style="margin-left: 1em;">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrows-down-up" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <line x1="17" y1="3" x2="17" y2="21"></line><path d="M10 18l-3 3l-3 -3"></path><line x1="7" y1="21" x2="7" y2="3"></line><path d="M20 6l-3 -3l-3 3"></path></svg> Коррекция
        </button>
      </div>
      <div class="col-2">
        <input type="file" class="form-control" accept=".pdf" @change="uploadFile('lava')" ref="pdf_file_lava">
      </div>
      <div class="col-2">
        <input type="file" class="form-control" accept=".pdf" @change="uploadFile('shtrek')" ref="pdf_file_shtrek">
      </div>
    </div>
  </div>
</template>

<script>
import Snap from "snapsvg-cjs";

export default {
  props: {
    calculate_results: Object,
  },
  data: function () {
    return {
      current_row: 0,
      total_rows: 0,
      process: {},
      paper: {},
      start_y_vent: 4,
      start_y_konv: 0,
      pixcel: 10,  // сколько пикселей в метре
      svg_block_height: 1100,
      svg_block_width: 1300,
      smest_vent: [],
      points_konv: [],
      points_vent: [],
      start_points_konv: [],
      start_points_vent: [],
      smest_konv: [],
      connecting_lines: {},
      harvester: {},
      konv_num: 0,
      vent_num: 0,
    }
  },
  watch: { 
    calculate_results: function(){
      this.draw_svg()
      this.stop_process()
    }
  },
  mounted(){
    this.draw_svg()
  },
  methods: {
    update_row(){
      if(this.current_row + 1 < this.total_rows){
        this.current_row += 1
        this.draw_svg()
        this.update_side_view(this.current_row)
      } else {
        this.stop_process()
      }
    },
    start_process(){
      this.process = {}
      this.process = setInterval(this.update_row, 500)
    },
    stop_process(){
      clearInterval(this.process)
      this.process = {}
      this.current_row = 0
      this.draw_svg()
    },
    async uploadFile(cut_type){
      let formData = new FormData()
      if(cut_type=="lava"){
        formData.append('image', this.$refs.pdf_file_lava.files[0])
      } else {
        formData.append('image', this.$refs.pdf_file_shtrek.files[0])
      }
      formData.append('cut_type', cut_type)
      formData.append('calculation', this.calculate_results.results_id)
      formData.append('row', this.current_row)
      console.log(this.current_row)
      const response = await fetch('/api/pdf-upload/', {
        method: 'POST',
        body: formData,
        credentials: "include",
      })
      if (response.ok) {
        let results = await response.json()
        this.$router.push({ name: 'lava', params: { id: results.id } })
      }
    },
    showCorrectionForm(){
      this.$emit("showCorrectionForm")
    },
    draw_svg(){
      let paper = Snap("#svg-top-view"),
          input_data_vent = this.calculate_results["input_data_vent"],
          results_vent = this.calculate_results["results_vent"],
          middle_part_heigth = input_data_vent["Llava"] * this.pixcel,
          start_x_konv = 100,
          input_data_konv = this.calculate_results["input_data_konv"],
          phi_avg = (input_data_vent["phi"] + input_data_konv["phi"]) / 2,
          start_x_vent = start_x_konv + (middle_part_heigth - 100) * Math.tan(phi_avg * Math.PI/180),
          results_konv = this.calculate_results["results_konv"];


      this.svg_block_width = start_x_vent + 500
      if(phi_avg < 0){
          start_x_vent = 100
          start_x_konv = start_x_vent + (middle_part_heigth - 100) * Math.tan(-phi_avg * Math.PI/180)
          this.svg_block_width = start_x_konv + 500
      }

      //this.konv_num = phi_avg < 0 ? input_data_vent["piket"] : input_data_konv["piket"],
      //this.vent_num = phi_avg < 0 ? input_data_vent["piket"] : input_data_konv["piket"],
      this.konv_num = 80
      this.vent_num = 80


      this.paper = paper
      paper.clear()

      this.connecting_lines = this.paper.g()
      this.harvester = this.paper.g()

      this.svg_block_height = middle_part_heigth + 150

      this.start_y_konv = this.start_y_vent + middle_part_heigth;

      // конвейерный боковой срез (нижний) похоже это вентиляционный
      let [points_konv, smest_konv] = this.draw_side_view(start_x_konv, this.start_y_konv, this.start_y_konv, start_x_vent - 14, input_data_konv, results_konv, "konv")
      this.points_konv = points_konv
      this.smest_konv = smest_konv

      // вентиляционный боковой срез (верхний)
      let [points_vent, smest_vent] = this.draw_side_view(start_x_vent, this.start_y_vent, this.start_y_vent + 150, 0, input_data_vent, results_vent, "vent")
      this.points_vent = points_vent
      this.smest_vent = smest_vent

      let piket_start_x = phi_avg < 0 ? points_vent[0] : points_konv[0]

      // таблички с номерами на верхнем срезе
      for(let i=0;i*this.pixcel<=2000;i+=10){
        this.paper.path(`M ${piket_start_x + i*this.pixcel} 127 h 30 v 15 h -30 z`).attr({stroke: "#999", strokeWidth: 1, fill: "#fff"})
        this.paper.text(piket_start_x + i*this.pixcel + 7, 140, this.vent_num - i/10).attr({'font-size': 12})
      }

      // таблички с номерами на нижнеи срезе
      for(let i=0;i*this.pixcel<=2000;i+=10){
        this.paper.path(`M ${piket_start_x + i*this.pixcel} ${this.start_y_konv + 127} h 30 v 15 h -30 z`).attr({stroke: "#999", strokeWidth: 1, fill: "#fff"})
        this.paper.text(piket_start_x + i*this.pixcel + 7, this.start_y_konv + 140, this.vent_num - i/10).attr({'font-size': 12})
      }

      // обводки поля
      paper.path(`M 0 ${this.start_y_vent} V${middle_part_heigth+105}`).attr({stroke: "#999", strokeWidth: 1})
      paper.path(`M ${start_x_vent+600} ${this.start_y_vent} V${middle_part_heigth+105}`).attr({stroke: "#999", strokeWidth: 1})
      paper.path(`M 0 ${this.start_y_vent} H${start_x_vent+600}`).attr({stroke: "#999", strokeWidth: 1})
      paper.path(`M 0 ${this.start_y_vent+150} H${start_x_vent+600}`).attr({stroke: "#999", strokeWidth: 1})
      paper.path(`M 0 ${this.start_y_konv} H${start_x_vent+600}`).attr({stroke: "#999", strokeWidth: 1})
      paper.path(`M 0 ${this.start_y_konv+150} H${start_x_vent+600}`).attr({stroke: "#999", strokeWidth: 1})


      // линии соединения конвеерной части и вентиляционной
      this.draw_connecting_lines()

      // линии соединения конвеерной части и вентиляционной по нарушению
      for(let i=0;i<=3;i++){
        paper.line(smest_konv[i], this.start_y_konv, smest_vent[i], this.start_y_vent + 150).attr({stroke: "#333", strokeWidth: 1})  
      }
      
      this.draw_harvester()
    },

    draw_side_view(start_x, start_y, bottom_y, offset_x, input_data, results, type){
      const 
        // ширина картинки
        width = 2000,
        // отступ от краёв
        offset = 10,
        // высота картинки
        height = 150


      let strokePattern = this.paper.path("M10-5-10,15M15,0,0,15M0-5-20,15").attr({
          stroke: "#333",
          strokeWidth: 1
      }).pattern(0, 0, 10, 10)

      // фон
      this.paper.rect(0, start_y, width + start_x + offset_x, height/2).attr({fill: "#999"})
      this.paper.rect(0, start_y, width + start_x + offset_x, height/2).attr({fill: strokePattern})
      this.paper.rect(0, start_y-1+height/2, width + start_x + offset_x, height/2).attr({fill: "#999"})
      this.paper.rect(0, start_y-1+height/2, width + start_x + offset_x, height/2).attr({fill: strokePattern})

      let 
        Lpr1 = results["Lpr1"],
        Lpr2 = results["Lpr2"],
        Lpr3 = results["Lpr3"],
        real_delta = results["real_delta"],
        h1 = results["h1"] * this.pixcel,
        h3 = results["h3"] * this.pixcel,
        beta_ = results["beta_"],
        direction = input_data["direction"],
        violation_type = input_data["violation_type"],

        triange_offset = (input_data["M"] / Math.tan(real_delta * Math.PI/180)) * this.pixcel,
        triange_offset2 = (input_data["H"] / Math.tan(real_delta * Math.PI/180)) * this.pixcel,

        point1 = {
          x: start_x + this.pixcel * (Lpr1 + Lpr2 + Lpr3) / 2,
          y: start_y + offset + (input_data["M"] + input_data["H"]) * this.pixcel,
        },

        point2 = {
          x: point1.x + triange_offset2,
          y: start_y + offset + input_data["M"] * this.pixcel,
        },

        point3 = {
          x: point1.x + triange_offset,
          y: start_y + offset + input_data["H"] * this.pixcel,
        },

        point4 = {
          x: point1.x + triange_offset2 + triange_offset,
          y: start_y + offset
        },

        // левая часть ниже правой (при подъёме слева или при спуске справа)
        left_part_lower = (violation_type == "asecent" && direction == "from_left") || (violation_type == "descent" && direction == "from_right")
      ;

      if(left_part_lower){
        point2 = {
          x: start_x + this.pixcel * (Lpr1 + Lpr2 + Lpr3) / 2,
          y: start_y + offset + (input_data["M"] + input_data["H"]) * this.pixcel,
        },

        point1 = {
          x: start_x + this.pixcel * (Lpr1 + Lpr2 + Lpr3) / 2 - triange_offset2,
          y: start_y + offset + (input_data["M"]) * this.pixcel,
        },

        point3 = {
          x: point1.x - triange_offset,
          y: start_y + offset,
        },

        point4 = {
          x: point2.x - triange_offset,
          y: start_y + offset + input_data["H"] * this.pixcel
        }
      }

      // левая часть
      this.paper.polygon([
        0, point2.y,
        0, point4.y,
        point4.x, point4.y,
        point2.x, point2.y,
      ]).attr({ fill: "#fff", stroke: "#000" })

      this.paper.polygon([
        0, point2.y,
        0, point2.y + (input_data["M"] - input_data["h_krepi"]) * this.pixcel,
        point2.x, point2.y + (input_data["M"] - input_data["h_krepi"]) * this.pixcel,
        point2.x, point2.y,
      ]).attr({fill: "#999", stroke: "#000"})


      // правая часть
      this.paper.polygon([
        start_x + width + offset_x, point3.y,
        start_x + width + offset_x, point3.y + input_data["M"] * this.pixcel,
        point1.x, point1.y,
        point3.x, point3.y,
      ]).attr({ fill: "#fff", stroke: "#000" })

      let
       coal_point3 = point1.x,
       coal_point3_triangle_offset = (input_data["M"] - input_data["h_krepi"]) * this.pixcel / Math.tan(real_delta * Math.PI/180)

      if(input_data["violation_type"] == "asecent"){
        coal_point3 += coal_point3_triangle_offset
      } else {
        coal_point3 -= coal_point3_triangle_offset
      }

      this.paper.polygon([
        start_x + width + offset_x, point1.y,
        start_x + width + offset_x, point1.y + (input_data["M"] - input_data["h_krepi"]) * this.pixcel,
        coal_point3, point1.y + (input_data["M"] - input_data["h_krepi"]) * this.pixcel,
        point1.x, point1.y,
      ]).attr({ fill: "#999", stroke: "#000" })


      // продолжение границы сместителя
      let dx1 = (height + start_y - point1.y) * Math.tan((90 - real_delta) * Math.PI/180);
      this.paper.line(point1.x, point1.y, point1.x - dx1, height + start_y).attr({stroke: "#333", strokeWidth: 1})
      let dx2 = (point4.y - start_y) * Math.tan((90 - real_delta) * Math.PI/180);
      this.paper.line(point4.x, point4.y, point4.x + dx2, start_y).attr({stroke: "#333", strokeWidth: 1})


      let 
        curves_point1 = {
          x: start_x,
          y: point2.y
        },
        curves_point2 = {
          x: curves_point1.x +  this.pixcel * Lpr1,
          y: point2.y + h1
        },
        curves_point3 = {
          x: curves_point2.x +  this.pixcel * Lpr3,
          y: curves_point2.y + h3
        },
        curves_point4 = {
          x: curves_point3.x +  this.pixcel * Lpr2,
          y: point1.y
        },
        curves_slope_1 = 1,  // в какую сторону выпуклость левой кривой
        curves_slope_2 = 0,  // в какую сторону выпуклость правой кривой

        // радиусы кривизны кривых
        RL1 = this.pixcel * Lpr1 / (2 * Math.sin(0.5 * beta_ * Math.PI/180)),
        RL2 = this.pixcel * Lpr2 / (2 * Math.sin(0.5 * beta_ * Math.PI/180));

      if(left_part_lower){
        curves_point2.y = point2.y - h1
        curves_point3.y = curves_point2.y - h3
        curves_slope_1 = 0
        curves_slope_2 = 1
      }


      let pad_points = [curves_point1.x, curves_point2.x, curves_point3.x, curves_point4.x],
          smest_points = [point1.x, point2.x, point3.x, point4.x];
      if(type == "konv") this.start_points_konv = Array.from(pad_points)
      if(type == "vent") this.start_points_vent = Array.from(pad_points)

      // кривые рассчётных точeк
      let curves = this.paper.group(
        this.paper.path(`M ${curves_point1.x} ${curves_point1.y} A ${RL1} ${RL1} 90 0 ${curves_slope_1} ${curves_point2.x} ${curves_point2.y},`).attr({stroke: "#f00", strokeWidth: 3, fill: 'none'}),

        this.paper.line(curves_point2.x, curves_point2.y, curves_point3.x, curves_point3.y).attr({stroke: "#f00", strokeWidth: 3}),

        this.paper.path(`M ${curves_point3.x} ${curves_point3.y} A ${RL2} ${RL2} 90 0 ${curves_slope_2} ${curves_point4.x} ${curves_point4.y},`).attr({stroke: "#f00", strokeWidth: 3, fill: 'none'}),

      ).attr({cursor: "move"})
      // let dy = input_data["h_krepi"] * this.pixcel;
      // curves.add(curves.clone().transform(`t0 -${dy}`))


      // линии от рассчётных точек до границы графика
      curves.add(
        this.paper.line(curves_point1.x, curves_point1.y, curves_point1.x, bottom_y).attr({stroke: "#333", strokeWidth: 1, "stroke-dasharray": 5}),
        this.paper.line(curves_point2.x, curves_point2.y, curves_point2.x, bottom_y).attr({stroke: "#333", strokeWidth: 1, "stroke-dasharray": 5}),
        this.paper.line(curves_point4.x, curves_point4.y, curves_point4.x, bottom_y).attr({stroke: "#333", strokeWidth: 1, "stroke-dasharray": 5})
      )
      if(Lpr3){
        curves.add(this.paper.line(curves_point3.x, curves_point3.y, curves_point3.x, bottom_y).attr({stroke: "#333", strokeWidth: 1, "stroke-dasharray": 5}))
      }


      /* точки кривой */
      curves.add(
        this.paper.circle(curves_point1.x, curves_point1.y, 3).attr({ fill: "#00f", stroke: "#000" }),
        this.paper.circle(curves_point2.x, curves_point2.y, 3).attr({ fill: "#00f", stroke: "#000" }),
        this.paper.circle(curves_point3.x, curves_point3.y, 3).attr({ fill: "#00f", stroke: "#000" }),
        this.paper.circle(curves_point4.x, curves_point4.y, 3).attr({ fill: "#00f", stroke: "#000" }),
      )

      let step_x = 10,
          step_y = 5,
          max_x = 100,
          parent_this = this;

      curves.drag(
        function (dx, dy) {
          let 
            tdx = + this.data('ot').split().dx + dx,
            tdy = + this.data('ot').split().dy + dy,
            newTx = Snap.snapTo(step_x, tdx, 100000000),
            newTy = Snap.snapTo(step_y, tdy, 100000000);

          if(newTx >= -max_x && newTx <= max_x){
            this.attr({transform: 't' + [newTx, newTy]});
            for(let i=0;i<=3;i++){
              if(type == "konv"){
                parent_this.points_konv[i] = parent_this.start_points_konv[i] + newTx
              }
              if(type == "vent") parent_this.points_vent[i] = parent_this.start_points_vent[i] + newTx
            }
            parent_this.draw_connecting_lines()
          }
        },
        function() {
          this.data('ot', this.transform().localMatrix);
        },
        function(){
          parent_this.current_row = 0
          parent_this.draw_harvester()
        }
      )

      // линии от сметителя до границ графика
      this.paper.line(point1.x, point1.y, point1.x, bottom_y).attr({stroke: "#333", strokeWidth: 1})
      this.paper.line(point2.x, point2.y, point2.x, bottom_y).attr({stroke: "#333", strokeWidth: 1})
      this.paper.line(point3.x, point3.y, point3.x, bottom_y).attr({stroke: "#333", strokeWidth: 1})
      this.paper.line(point4.x, point4.y, point4.x, bottom_y).attr({stroke: "#333", strokeWidth: 1})


      /* точки линии сместителя */
      this.paper.circle(point1.x, point1.y, 3).attr({ fill: "#f00", stroke: "#000" })
      this.paper.circle(point2.x, point2.y, 3).attr({ fill: "#f00", stroke: "#000" })
      this.paper.circle(point3.x, point3.y, 3).attr({ fill: "#f00", stroke: "#000" })
      this.paper.circle(point4.x, point4.y, 3).attr({ fill: "#f00", stroke: "#000" })

      return [pad_points, smest_points]

    },
    draw_harvester(){
      // ползунок комбайна
      let 
          input_data_vent = this.calculate_results["input_data_vent"],
          direction = input_data_vent["direction"],
          step_width = input_data_vent["r"] * this.pixcel - 1,
          max_x = this.points_vent[3] - this.points_konv[0],
          total_parts = Math.ceil(input_data_vent["Llava"] / input_data_vent["l"]),
          middle_part_heigth = input_data_vent["Llava"] * this.pixcel,
          current_x = this.points_konv[0] + step_width * this.current_row,
          parts_dx = (this.konv_num - this.vent_num) * this.pixcel / total_parts,
          harvester_width = step_width+30,
          parent_this = this,
          phi=input_data_vent["phi"],
          parts_dy = (middle_part_heigth-150) / total_parts;

      if(direction == "from_right"){
        if(phi>0){
          current_x = this.points_vent[3] + harvester_width - step_width * this.current_row
        } else {
          current_x = this.points_konv[3] + harvester_width - step_width * this.current_row
          max_x = this.points_konv[3] - this.points_vent[0]
        }
      } else if(phi < 0){
        current_x = this.points_vent[0] + step_width * this.current_row
        max_x = this.points_konv[3] - this.points_vent[0]
      }

      this.total_rows = max_x / step_width;

      this.harvester.clear()

      current_x = current_x - harvester_width
      let parts_x = current_x

      for(let i=1;i<=total_parts;i+=1){
        let parts_y = 4+150+i*parts_dy
        this.harvester.add(
          this.paper.path(`M ${parts_x} ${parts_y} h${harvester_width}`).attr({stroke: "#000", strokeWidth: 1}),
          this.paper.text(parts_x+5, parts_y-2, total_parts-i+1).attr({'font-size':parts_dy-2, 'font-weight': 'bold'}),
        )
        parts_x = parts_x + parts_dx;
      }

      this.harvester.add(
        this.paper.path(`M ${current_x} ${4+150} h ${harvester_width} l ${(this.konv_num - this.vent_num) * this.pixcel} ${middle_part_heigth-150} h -${harvester_width} z` ).attr({stroke: "#ccc",
          strokeWidth: 1, fill: "yellow", "fill-opacity": 0.5, cursor: "move",})
      )

      this.harvester.drag(
        function (dx) {
          var tdx = + this.data('ot').split().dx + dx;

          var newTx = Snap.snapTo(step_width, tdx, 100000000)
          if((direction == "from_left" && newTx >= 0) ||(direction == "from_right" && newTx <= 0) && newTx < max_x){
            this.attr({transform: 't' + [newTx, 0]});
            this.current_row = Math.abs(newTx) / step_width;
            parent_this.current_row = this.current_row
          }
        },
        function () {
           this.data('ot', this.transform().localMatrix);
        },
        function (){
          parent_this.update_side_view(this.current_row)
        }
      );
    },

    draw_connecting_lines(){
      // линии соединения кривых на корвейерной и вентиляционной части
      this.connecting_lines.clear()
      for(let i=0;i<=3;i++){
        this.connecting_lines.add(
          this.paper.line(this.points_konv[i], this.start_y_konv, this.points_vent[i], this.start_y_vent + 150).attr({stroke: "#333", strokeWidth: 1, "stroke-dasharray": 5})
        )
      }
    },

    update_side_view(current_row) {
      let input_data_vent = this.calculate_results["input_data_vent"],
          step_width = input_data_vent["r"] * this.pixcel - 1,
          middle_part_heigth = input_data_vent["Llava"] * this.pixcel,
          total_parts = input_data_vent["Llava"] / input_data_vent["l"],
          parts_dy = (middle_part_heigth-100) / total_parts;

      // первая секция, которая вошла в нарушение
      let first_section_in_violation = current_row * step_width / ((this.points_vent[0] - this.points_konv[0]) / (middle_part_heigth-100)) / parts_dy

      // первая секция, которая вышла из нарушения
      let first_section_out_violation = (current_row * step_width - this.points_konv[3] + this.points_konv[0]) / ((this.points_vent[3] - this.points_konv[3]) / (middle_part_heigth - 100)) / parts_dy + 1

      this.$emit("showSideView", current_row, this.total_rows, first_section_in_violation, first_section_out_violation);
    }


  }
};
</script>

<style>
  #svg_cont {height: 800px;overflow: scroll;}
</style>