import Vue from 'vue'
import VueRouter from 'vue-router'

// import auth from './middleware/auth'
import guest from './middleware/guest'

import Index from '../pages/index/Page.vue'
import Lava from '../pages/lava/Page.vue'

import LoginPage from '../pages/login/Page.vue'
import Login from '../pages/login/Login.vue'
import LoginJWT from '../pages/login/LoginJWT.vue'
import ChangePass from '../pages/login/ChangePass.vue'
import RecoveryPass from '../pages/login/RecoveryPass.vue'
import Page404 from '../pages/404.vue'

Vue.use(VueRouter)


const routes = [
  { path: '', redirect: '/new' },
  {
    path: '/login',
    component: LoginPage,
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
        meta: {
          middleware: guest
        },
      },
      {
        path: 'jwt/:token',
        name: 'loginJWT',
        component: LoginJWT,
      },
      {
        path: 'recovery-pass',
        name: 'recovery-pass',
        component: RecoveryPass,
      },
      {
        path: 'change-pass',
        name: 'change-pass',
        component: ChangePass,
      },
    ]
  },
  {
    path: '/new/',
    component: Index,
    name: 'new',
  },
  {
    path: '/results/:id',
    component: Index,
    name: 'results',
  },
  {
    path: '/lava/:id',
    component: Lava,
    name: 'lava',
  },
  {
    path: '*',
    name: '404',
    component: Page404
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
  }
  return middleware(context)
})

export default router