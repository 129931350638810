<template>
  <div class="card-body">

    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Тип прохода</label>
      <div class="col">
        <select class="form-select" v-model="form.violation_type">
          <option value="descent">Спуск</option>
          <option value="asecent">Подъём</option>
        </select>
      </div>
    </div>

    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Направление прохода</label>
      <div class="col">
        <select class="form-select" v-model="form.direction">
          <option value="from_left">Слева-направо</option>
          <option value="from_right">Справа-налево</option>
        </select>
      </div>
    </div>


    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Длина лавы</label>
      <div class="col">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="form.Llava">
          <span class="input-group-text unit">
            м.
          </span>
        </div>
      </div>
    </div>

    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Высота крепи</label>
      <div class="col">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="form.h_krepi" @change="() => { if(form.h_krepi > 5 ) { form.h_krepi = 5 }}">
          <span class="input-group-text unit">
            м
          </span>
        </div>
      </div>
    </div>

    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Угол падения пласта, γ</label>
      <div class="col">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="form.gamma" @change="() => { if(form.gamma > 25 ) { form.gamma = 25 }}">
          <span class="input-group-text unit">
            º
          </span>
        </div>
      </div>
    </div>

    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">ПК по вент. штреку</label>
      <div class="col">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="form.piket2">
          <span class="input-group-text unit">
          </span>
        </div>
      </div>
    </div>

    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">ПК по конв. штреку</label>
      <div class="col">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="form.piket2">
          <span class="input-group-text unit">
          </span>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  props: {
    form: Object,
  },
};
</script>

