<template>
  <div class="modal" tabindex="-1" role="dialog" v-on:keyup.ctrl.enter="save()" id="correction_modal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Коррекция</h5>
          <p @click="hide()" title="Закрыть (Esc)">
            <a type="button" class="btn-close"></a>
          </p>
        </div>
        <div class="modal-body">
          <div class="row">
              <div class="col-6">
                <div class="card">
                  <div class="card-header">
                    <ul class="nav nav-pills card-header-pills">
                      <li class="nav-item">
                          <h4 class="card-title">Секции</h4>
                      </li>
                    </ul>
                  </div>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <td class="text-center">№ секции</td>
                        <td class="text-center">Δh, м</td>
                        <td class="text-center">αº</td>
                        <td class="text-center">βº</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in corrections" :key="index" >
                        <td>
                          <input type="number" class="form-control" v-model="row.num" />
                        </td>
                        <td>
                          <input type="number" class="form-control" v-model="row.h" />
                        </td>
                        <td>
                          <input type="number" class="form-control" v-model="row.alpha" />
                        </td>
                        <td>
                          <input type="number" class="form-control" />
                        </td>
                      </tr>
                     </tbody>
                  </table>
                </div>
              </div>
              <div class="col-6">
                <div class="card">
                 <div class="card-header">
                    <ul class="nav nav-pills card-header-pills">
                      <li class="nav-item">
                          <h4 class="card-title">Нарушение</h4>
                      </li>
                    </ul>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <label class="form-label">Секция</label>
                        <input type="text" class="form-control mb-3" v-model="violation.left_section">
                        <input type="text" class="form-control mb-3" v-model="violation.right_section">
                      </div>
                      <div class="col-6">
                        <label class="form-label">Смещение</label>
                        <input type="text" class="form-control mb-3" v-model="violation.left_section_dx" value="0">
                        <input type="text" class="form-control mb-3" v-model="violation.right_section_dx" value="0">
                      </div>

                    </div>

                  </div>
                </div>
              </div>
          </div>
        </div>

        <div class="modal-footer">
            <a class="btn me-auto" @click="add_row()"><i class="fa fa-plus"></i> Добавить ещё строку </a>
            <a class="btn btn-primary" @click="save()"><i class="fa fa-save"></i> Сохранить </a>
            <a class="btn" @click="hide()"><i class="fa fa-times"></i> Закрыть </a>
            <a class="btn btn-warning" @click="reset()"><i class="fa fa-trash"></i> Очистить </a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: "CorrectionForm",
  props: {
  },
  data: function () {
    return {
      violation: {},
      corrections: [],
    };
  },
  mounted() {
    this.reset_corrections()
  },
  methods: {
    reset_corrections(){
      this.corrections = []
      for(let i=0;i<16;i++){
        this.corrections.push({num: "", h: "", alpha: ""})
      }
      this.violation = {}
    },
    save(){
      this.$emit("updateCorrections", this.corrections, this.violation)
    },
    hide(){
      this.$emit("hideCorrectionForm")
    },
    reset(){
      this.reset_corrections()
      this.$emit("updateCorrections", this.corrections)
    },
    add_row(){
      this.corrections.push({num: "", h: "", alpha: ""})
    }
  },
};
</script>

<style>
  #correction_modal .card-header {padding: 3px 12px;}
  #correction_modal input.form-control {padding: 2px 5px;}
  #correction_modal .table td {padding: 1px 8px}
  #correction_modal .modal-body {padding: 5px 10px;}
</style>