<template>
  <g id="ruler" cursor="move">
    <rect :x="start_x" y="0" :width="width" :height="height" fill="#ff0" opacity="0.8" />
    <line :x1="start_x" :y1="y" :x2="start_x+width" :y2="y" v-for="y in ruler_lines" v-bind:key="y" stroke="#666" />
  </g>
</template>

<script>
import Snap from "snapsvg-cjs";

export default {
  props: {
    input_data: Object,
    pixcel: Number,
    border_thickness: Number,
  },
  watch: { 
    input_data: function(){
      this.draw_svg()
    },
  },
  mounted(){
    this.draw_svg()
  },
  data: function () {
    return {
      start_x: 0,
      height: 0,
      width: 15,
      ruler_lines: [],
    }
  },
  methods: {
    draw_svg(){
      let 
        ruler = Snap("#ruler"),
        pure_height = this.input_data.H + this.input_data.M,
        start_y = this.border_thickness / this.pixcel,
        step_width = 10,
        y = start_y

      this.height = pure_height * this.pixcel + this.border_thickness * 2
      this.ruler_lines = []

      while(y <= pure_height + start_y){
        this.ruler_lines.push(y * this.pixcel)
        y += 1
      }

      ruler.drag(
        function (dx) {
          var tdx = + this.data('ot').split().dx + dx;

          var newTx = Snap.snapTo(step_width, tdx, 100000000)
          if(newTx >= 0){
            this.attr({transform: 't' + [newTx, 0]});
            this.current_row = newTx / step_width;
          }
        },
        function () {
           this.data('ot', this.transform().localMatrix);
        }
      );

    }
  }
};
</script>
