<template>
  <rect x="0" y="0" width="100%" :height="this.height" fill="url(#stroke-pattern)" id="lava" />
</template>

<script>

export default {
  props: {
    H: Number,
    M: Number,
    pixcel: Number,
    border_thickness: Number
  },
  watch: { 
    H: function(){
      this.draw_svg()
    },
    M: function(){
      this.draw_svg()
    },
  },
  mounted(){
    this.draw_svg()
  },
  data: function () {
    return {
      height: 0,
    }
  },
  methods: {
    draw_svg(){
      this.height = (this.H+this.M)*this.pixcel+this.border_thickness*2
    }
  }
};
</script>
