<template>
  <div class="card-body">
    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Угол поперечной устойчивости крепи, α</label>
      <div class="col">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="form.alpha">
          <span class="input-group-text unit">
            º
          </span>
        </div>
      </div>
    </div>

    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Угол продольной устойчивости крепи, β</label>
      <div class="col">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="form.beta">
          <span class="input-group-text unit">
            º
          </span>
        </div>
      </div>
    </div>

    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Угол изгиба по длине лавы (попереч. пл), μ</label>
      <div class="col">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="form.mu">
          <span class="input-group-text unit">
            º
          </span>
        </div>
      </div>
    </div>

    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Угол изгиба по простиранию (прод. пл), ρ</label>
      <div class="col">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="form.ro">
          <span class="input-group-text unit">
            º
          </span>
        </div>
      </div>
    </div>

    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Длина секции конвейера, l</label>
      <div class="col">
        <div class="input-group">
          <input type="number" step="0.01" class="form-control text-end" v-model="form.l">
          <span class="input-group-text unit">
            м.
          </span>
        </div>
      </div>
    </div>


    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Ширина захвата комбайна, r</label>
      <div class="col">
        <div class="input-group">
          <input type="number" step="0.01" class="form-control text-end" v-model="form.r">
          <span class="input-group-text unit">
            м.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: Object,
  },
};
</script>

