<template>
  <layout>
    <Form source="/api/calculate/" @showResults="show_results"></Form>

    <div class="card" style="padding-right:25px;">
        <TopView 
          :calculate_results="params"
          v-if="show_svg"
          @showSideView="show_side_view"
          @showCorrectionForm="show_correction_form"
        ></TopView>
    </div>
    <div class="card">
        <FrontView 
          :calculate_results="params" 
          :row="row" 
          :total_rows="total_rows"
          :first_section_in_violation="first_section_in_violation"
          :first_section_out_violation="first_section_out_violation"
          :corrections="corrections"
          :violation="violation"
          :show_coal="true"
          :show_lava="true"
          :show_ruler="true"
          v-if="show_svg"
        ></FrontView>
    </div>
    <CorrectionForm v-show="correction_form" @hideCorrectionForm="hide_correction_form" @updateCorrections="update_corrections"></CorrectionForm>
  </layout>
</template>

<script>
import Layout from "../../layouts/Base.vue";
import TopView from "./svg/TopView.vue";
import FrontView from "./front-view/FrontView.vue";

import Form from "./Form.vue";
import CorrectionForm from "./CorrectionForm.vue";

export default {
  components: {
    Layout, Form, TopView, FrontView, CorrectionForm
  },
  data: function () {
    return {
      params: Object,
      row: 0,
      total_rows: 0,
      show_svg: false,
      first_section_in_violation: 0,
      first_section_out_violation: 0,
      correction_form: false,
      corrections: [],
      violation: {},
    }
  },
  methods: {
    show_results(results){
      this.params = results
      this.show_svg = true
    },
    show_side_view(row, total_rows, first_section_in_violation, first_section_out_violation){
      this.row = row
      this.total_rows = total_rows
      this.first_section_in_violation = first_section_in_violation
      this.first_section_out_violation = first_section_out_violation
    },
    show_correction_form(){
      this.correction_form = true
    },
    hide_correction_form(){
      this.correction_form = false
    },
    update_corrections(corrections, violation){
      this.corrections = corrections
      this.violation = violation
      this.correction_form = false
    },
  },
  mounted() {
  },
};
</script>




<style>
  .svg-block {
    background-image:  linear-gradient(#e9e9e9 1.2000000000000002px, transparent 1.2000000000000002px), linear-gradient(90deg, #e9e9e9 1.2000000000000002px, transparent 1.2000000000000002px), linear-gradient(#e9e9e9 0.6000000000000001px, transparent 0.6000000000000001px), linear-gradient(90deg, #e9e9e9 0.6000000000000001px, #ffffff 0.6000000000000001px);
    background-size: 30px 30px, 30px 30px, 6px 6px, 6px 6px;
    background-position: -1.2000000000000002px -1.2000000000000002px, -1.2000000000000002px -1.2000000000000002px, -0.6000000000000001px -0.6000000000000001px, -0.6000000000000001px -0.6000000000000001px;
  }
</style>
