<template>
  <form v-on:keyup.ctrl.enter="save()" class="compute-form">
    <div class="row">
        <div class="col-3">
          <div class="card">
            <div class="card-header">
              <ul class="nav nav-pills card-header-pills">
                <li class="nav-item">
                    <h4 class="card-title">Параметры комплекса</h4>
                </li>
                <li class="nav-item ms-auto" v-if="false">
                  <select class="form-select" @change="select_harvester()" v-model="selected_harvester">
                    <option value="0">> выбрать комплекс</option>
                    <option v-for="harvester in harvesters" :value="harvester" :key="harvester.id">
                      {{ harvester.vid }}
                    </option>
                  </select>
                </li>
              </ul>
            </div>

            <harvester :form="form"></harvester>
          </div>
        </div>

        <div class="col-3">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Параметры нарушения (вентиляционный штрек)</h4>
            </div>
            <vent :form="form.vent"></vent>
          </div>
        </div>

        <div class="col-3">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Параметры нарушения (конвейерный штрек)</h4>
            </div>
              <konv :form="form.konv"></konv>
          </div>
        </div>


        <div class="col-3">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Дополнительно:</h4>
            </div>
              <other :form="form"></other>
          </div>
        </div>

    </div>

    <div class="mb-2 text-center">
      <button type="button" @click="save()" title="Рассчитать (Ctrl+Enter)" class="btn btn-primary">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="4" y="3" width="16" height="18" rx="2" /><rect x="8" y="7" width="8" height="3" rx="1" /><line x1="8" y1="14" x2="8" y2="14.01" /><line x1="12" y1="14" x2="12" y2="14.01" /><line x1="16" y1="14" x2="16" y2="14.01" /><line x1="8" y1="17" x2="8" y2="17.01" /><line x1="12" y1="17" x2="12" y2="17.01" /><line x1="16" y1="17" x2="16" y2="17.01" /></svg>
        Выполнить расчёт
      </button>
    </div>


    <results :result="result_vent" :logs="log_vent" name="Результаты расчёта по вентиляционному штреку"></results>
    <results :result="result_konv" :logs="log_konv" name="Результаты расчёта по конвейерному штреку"></results>


  </form>
</template>


<script>
import harvester from "./form-parts/harvester.vue";
import konv from "./form-parts/konv.vue";
import vent from "./form-parts/vent.vue";
import other from "./form-parts/other.vue";
import results from "./form-parts/results.vue";

export default {
  name: "Form",

  components: {
    harvester, konv, vent, other, results,
  },

  data: function () {
    return {
      form: {
        violation_type: "descent",
        direction: "from_left",
        alpha: 20,
        beta: 20,
        gamma: 5,
        mu: 4,
        ro: 4,
        l: 1.5,
        r: 0.8,
        Llava: 200,
        h_krepi: 3,
        konv: {
          M: 4,
          H: 2.5,
          phi: 30,
          delta: 60,
        },
        vent: {
          M: 4,
          H: 2.5,
          phi: 30,
          delta: 60,
        }
      },
      formError: {},
      log_konv: "",
      log_vent: "",
      result_vent: {},
      result_konv: {},
      harvesters: [],
      selected_harvester: 0,
    };
  },
  props: {
    source: String,
  },
  async mounted() {
    this.load_harvesters();
    if(this.$route.name == "results"){
      this.load_results(this.$route.params.id)
    }
    //this.save();
  },
  methods: {
    async save() {
      const response = await fetch(this.source, {
        method: "post",
        body: JSON.stringify(this.form),
        headers: {"Content-Type": "application/json"},
        credentials: "include",
      });
      if (response.ok) {
        let results = await response.json();
        this.$emit("showResults", results)
        this.$router.push({name: "results", params: { id: results.results_id }}).catch(() => {});
      } else {
        this.formError = await response.json();
      }
    },
    start_process(){
      this.$emit("StartProcess");
    },
    async load_harvesters() {
      const response = await fetch("/api/harvesters/list/");
      if (response.ok) {
        this.harvesters = await response.json();
      }
    },
    async load_results(id) {
      const response = await fetch(`/api/results/${id}/`);
      if (response.ok) {
        let results = await response.json()
        this.form = results.input_data_konv
        this.form.konv = JSON.parse(JSON.stringify(results.input_data_konv))
        this.form.vent = JSON.parse(JSON.stringify(results.input_data_vent))
        this.log_vent = results.logs_vent
        this.log_konv = results.logs_konv
        this.result_vent = results.results_vent
        this.result_konv = results.results_konv
        this.$emit("showResults", results)
        console.log(this.form)
      }
    },
    select_harvester(){
      this.form["alpha"] = this.selected_harvester["alpha"];
      this.form["beta"] = this.selected_harvester["beta_pad"];
      this.form["l"] = this.selected_harvester["dlina_l"];
      this.form["mu"] = this.selected_harvester["mu"];
      this.form["ro"] = this.selected_harvester["ro"];
      this.form["r"] = this.selected_harvester["shirina_r"];
    }

  },
};
</script>

<style>
 .compute-form input {text-align: right;padding-right: 1.25em;}
 .compute-form .unit {width:  40px;text-align: center;}
 #log-data {max-height: 364px;background: none; border: none; padding: 0;}
 .subtitle {border-bottom: 1px dashed #ccc;}
</style>