<template>
    <div style="background-position: center; background-repeat: no-repeat; background-size: cover;" v-bind:style="{'background-image':png_path,'width':png_width,'height':png_height}">
        <div class="menu" style="width: 80px;position: fixed;z-index: 9999;">
          <select class="form-select" :model="this.current_row" @change="set_row">
            <option v-for="row in this.rows" v-bind:key="row">{{ row }}</option>
          </select>
        </div>
        <FrontView 
          :calculate_results="calculate_results" 
          :row="row" 
          :first_section_in_violation="first_section_in_violation"
        ></FrontView>
    </div>
</template>

<script>
import FrontView from "../index/front-view/FrontView.vue";
import Snap from "snapsvg-cjs";

export default {
  components: {
    FrontView
  },
  data: function () {
    return {
      png_path: "",
      png_width: "0",
      png_height: "0",
      calculate_results: {},
      first_section_in_violation: 0,
      row: 0,
      rows: [],
      current_row: 10,
    }
  },
  methods: {
    set_row(event){
      this.row = parseInt(event.target.value)
      this.first_section_in_violation = this.row * 1.0429345131825356
    }
  },
  mounted() {
    // this.get_png_image()
    let 
      svg = Snap("#svg-side-view"),
      move = function(dx,dy) {
        this.attr({transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [dx, dy]});
      },
      start = function() {this.data('origTransform', this.transform().local )}

    fetch(`/api/pdf/${this.$route.params.id}/`)
      .then((response) => response.json())
      .then((results) => {
        this.png_path = `url(${results.png_path})`
        this.png_width = `${results.png_width}px`
        this.png_height = `${results.png_height}px`
        this.calculate_results = results.calculation_results

        this.row = results.calculation_results.row
        this.first_section_in_violation = this.row * 1.0429345131825356
        this.rows = [...Array(150).keys()]
        let gamma = parseFloat(this.calculate_results.input_data_konv.gamma)

        svg.transform(`translate(0, 550) rotate(${gamma}})`)
        svg.drag(move, start)

      });


  },
};
</script>

