<template>
  <div style="width:100%;height: 600px;" :style="{ 'margin-top': marginTop + 'px' }">
    <svg id="svg-side-view" style="width: 100%;height: 400px;transform-origin: 0 0;">
      <defs>
        <pattern id="stroke-pattern" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="10" height="10" fill="#999" />
          <path d="M10-5-10,15M15,0,0,15M0-5-20,15" stroke="#333" />
        </pattern>
      </defs>

      <Lava :H="corrected_data.H" :M="corrected_data.M" :pixcel="this.pixcel" :border_thickness="this.border_thickness" v-if="show_lava"/>
      <Coal :input_data="corrected_data" :results="this.results" :pixcel="this.pixcel" :border_thickness="this.border_thickness" v-if="show_coal" :start_x="this.smest_start_x" />
      <Support 
        :point="support.point"
        :alpha="support.alpha"
        :height="support.height"
        :width="support.width"
        :num="support.num"
        :part="support.part"
        v-for="support in supports" :key="support.num"
       />
      <Ruler :input_data="this.input_data" :pixcel="this.pixcel" :border_thickness="this.border_thickness" v-if="show_ruler"></Ruler>
    </svg>
  </div>
</template>

<script>
import Snap from "snapsvg-cjs";

import Lava from "./Lava.vue";
import Coal from "./Coal.vue";
import Support from "./Support.vue";
import Ruler from "./Ruler.vue";


export default {
  components: {
    Lava, Coal, Support, Ruler
  },

  props: {
    calculate_results: Object,
    // текущий номер цикла
    row: Number, 
    first_section_in_violation: Number,
    first_section_out_violation: Number,
    // общее количество циклов
    total_rows: Number,

    // коррекции
    corrections: Array,
    violation: Object,
    show_coal: Boolean,
    show_lava: Boolean,
    show_ruler: Boolean,
  },
  data: function () {
    let
      pixcel = 20,
      border_thickness = 10,
      calculate_results = JSON.parse(JSON.stringify(this.calculate_results)),
      input_data = JSON.parse(JSON.stringify(calculate_results))
    return {
      svg: undefined,
      pixcel: pixcel, // сколько пикселей в метре
      input_data: input_data,
      corrected_data: calculate_results.input_data_konv, // данные скорректированные по конкретному срезу
      results: calculate_results.results_konv,
      results_vent: calculate_results.vent,
      border_thickness: border_thickness, // отступы сверху и снизу
      supports: [],
      smest_start_x: 0,
      marginTop: 0,
    }
  },
  watch: { 
    calculate_results: function(){
      this.draw_svg()
      this.rotate_svg()
    },
    row: function(){
      this.draw_svg()
    },
    corrections: {
      handler: function () {
        this.draw_svg()
      },
      deep: true
    },
    violation: {
      handler: function () {
        this.draw_svg()
      },
      deep: true
    },
  },
  mounted(){
    this.draw_svg()
    this.rotate_svg()
  },
  methods: {
    draw_svg(){    
      this.svg = Snap("#svg-side-view");
      this.input_data = this.calculate_results.input_data_konv
      this.results = this.calculate_results.results_konv
      if(!this.input_data) return

      let
       row_k = this.row/this.total_rows || 0,
       data_konv = this.calculate_results.input_data_konv,
       results_konv = this.calculate_results.results_konv,
       data_vent = this.calculate_results.input_data_vent,
       results_vent = this.calculate_results.results_vent,
       phi = data_konv.phi,
       H_konv = data_konv.H,
       H_vent = data_vent.H,
       H = H_konv - (H_konv-H_vent) * row_k,
       Lpad1_konv = parseFloat(results_konv.Lpad1),
       Lpad1_vent = parseFloat(results_vent.Lpad1),
       Lpad1 = Lpad1_konv - (Lpad1_konv-Lpad1_vent) * row_k,
       Lpad2_konv = parseFloat(results_konv.Lpad2),
       Lpad2_vent = parseFloat(results_vent.Lpad2),
       Lpad2 = Lpad2_konv - (Lpad2_konv-Lpad2_vent) * row_k,
       Lpad3_konv = parseFloat(results_konv.Lpad3),
       Lpad3_vent = parseFloat(results_vent.Lpad3),
       Lpad3 = Lpad3_konv - (Lpad3_konv-Lpad3_vent) * row_k

      this.corrected_data = {
        H: H,
        l: parseFloat(this.input_data.l),
        M: parseFloat(this.input_data.M),
        violation_type: this.input_data.violation_type,
        Llava: parseFloat(this.input_data.Llava),
      }


      const
        l = parseFloat(this.input_data.l),
        violation_type = this.input_data.violation_type,
        Llava = parseFloat(this.input_data.Llava),
        h_krepi = parseFloat(this.input_data.h_krepi),
        section_num_in_violation = Math.ceil((Lpad1 + Lpad2 + Lpad3) / l),

        support_width = l * this.pixcel,

        support_count = Math.ceil(Llava / l),
        support_height = h_krepi * this.pixcel -1,
        direction = this.input_data.direction

      if(this.first_section_out_violation){
        this.smest_start_x = this.first_section_out_violation * support_width + Lpad1 * this.pixcel
      }
      this.supports = []
      this.svg.attr('width', this.input_data.Llava * this.pixcel + support_width)

      let 
        x = 0,
        left_part_y = this.border_thickness + 1,
        right_part_y = H * this.pixcel + this.border_thickness + 1,
        y = left_part_y,
        dx = support_width,
        alpha = 0,
        part = "",
        first_section_in_violation = Math.ceil(this.first_section_in_violation)

      if(violation_type == "descent"){
        left_part_y = H * this.pixcel + this.border_thickness + 1
        right_part_y = this.border_thickness + 1
      }

      for(let i=1; i<=support_count; i++){

        if(i < Math.ceil(this.first_section_out_violation)) {
          // слева от нарушения
          alpha = 0
          dx = support_width
          y = left_part_y
          part = ""
        } else if(i <= first_section_in_violation) {
          // в нарушении
          let
           num_in_violation = Math.abs(first_section_in_violation - i - section_num_in_violation),
           support_params = this.get_support_params_by_number_in_violation(num_in_violation)
          dx = support_params.dx
          y = support_params.y
          alpha = support_params.alpha
          part = support_params.part
        } else {
          // справа от нарушения
          alpha = 0
          dx = support_width
          y = right_part_y
          part = ""
        }

        let support_number = i
        if(direction == "from_right" || phi < 0) support_number=support_count-i+1

        this.supports.push({
          num:support_number,
          point: {x: x, y: y},
          width: support_width,
          height: support_height,
          alpha: alpha,
          part: part,
        })
        x += dx
      }
    },
    rotate_svg(){
      let
       gamma = this.calculate_results.input_data_konv.gamma,
       direction = this.input_data.direction
      if(direction == "from_right") gamma = -gamma
      this.svg.transform(`r${gamma} 0,0`)
      if(gamma < 0)
        this.marginTop = -gamma * 35

    },
    get_support_params_by_number_in_violation(num_in_violation){
      let
        l = parseFloat(this.input_data.l),
        violation_type = this.input_data.violation_type,
        support_width = l * this.pixcel,
        Lpad1 = parseFloat(this.results.Lpad1),
        Lpad2 = parseFloat(this.results.Lpad2),
        Lpad3 = parseFloat(this.results.Lpad3),
        H = parseFloat(this.input_data.H),
        alpha = 0,
        current_alpha = 0,
        dy = 0,
        dx = 0,
        part = "",
        supports_in_violation = Math.ceil((Lpad1 + Lpad2 + Lpad3) / l),
        left_part_y = this.border_thickness + 1,
        right_part_y = H * this.pixcel + this.border_thickness + 1,
        y = left_part_y,
        k = H - 1.2,  // поправочный коэф
        supports_params = {}

      if(violation_type == "descent"){
        y = right_part_y
      }


      if (num_in_violation == 0)
        // баг какой-то
        return {
          dx: support_width,
          dy: 0,
          y: y,
          alpha: current_alpha,
          part: part,
      }

      for(let i=1; i<=supports_in_violation; i++){
        if(i <= Lpad1 / l) {
          // L1
          alpha += this.results["alpha_"]*Math.cos(this.results["alpha_"] * Math.PI/180) / (Lpad1 * this.pixcel / support_width)
          part = "L1"
        } else if (i > (Lpad1 + Lpad3) / l)  {
          // L2
          alpha -= this.results["alpha_"] / (Lpad2 * this.pixcel / support_width + k)
          part = "L2"
        } else {
          // L3
          part = "L3"
        }
        dy = support_width * Math.sin(alpha * Math.PI/180)
        dx = support_width * Math.cos(alpha * Math.PI/180)

        if(violation_type == "asecent"){
          current_alpha = alpha
        } else {
          current_alpha = -alpha
        }

        supports_params[i] = {
          dx: dx,
          dy: dy,
          y: y,
          alpha: current_alpha,
          part: part,
        }

        if(violation_type == "asecent"){
          y += dy
        } else {
          y -= dy
        }      
      }
      if(supports_params[num_in_violation]) return supports_params[num_in_violation]
        else return {
          dx: dx,
          dy: dy,
          y: y,
          alpha: current_alpha,
          part: part,
        }
    }
  },
};
</script>
